import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from './DogBreeds.module.scss';
import cs from 'classnames';
import createSlug from 'src/components/shared/createSlug/createSlug';

class DogBreeds extends Component {
  state = {
    activeLetter: 'a',
    letters: [],
    filteredBreeds: {},
  };

  componentDidMount() {
    const letters = this.breedsLetters(this.props.breeds);
    this.filter(this.props.breeds, letters.length > 0 ? letters[0] : "a");
  }

  filter = (breeds, letter) => {
    const filterLetter = letter;
    this.setState({
      activeLetter: letter,
    });
    const filteredBreeds = breeds.filter(breed => {
      return breed.breed.charAt(0).toUpperCase() === filterLetter.toUpperCase();
    });
    this.setState({
      filteredBreeds: filteredBreeds,
    });
  };

  breedsLetters = (breeds) => {
    const letters = breeds.map(breed => {
      return breed.breed.charAt(0).toLowerCase();
    });
    letters.sort();
    let unique = [...new Set(letters)];
    this.setState({
      letters: unique,
    });
    return unique;
  };


  render() {
    const { breeds } = this.props;
    const { activeLetter, filteredBreeds, letters } = this.state;
    return (
      <>
        <section className={styles.dogBreedsWrap}>
          {breeds && <div className={styles.filterWrap}>
            <ul className={styles.filterList}>
              {letters.length > 0 && letters.map(letter => {
                const isActive = activeLetter.toUpperCase() === letter.toUpperCase();
                return (
                  <li
                    className={cs(styles.filterItem, { [styles.active]: isActive })}
                    key={letter}>
                    <p className={styles.letter} onClick={() => this.filter(breeds, letter)}>{letter}</p>
                  </li>
                );
              })}
            </ul>
          </div>}
          {filteredBreeds.length > 0 &&
          filteredBreeds.map(dogBreed => {
            const { breed, id } = dogBreed;
            return (
              <Link key={id} className={styles.breed} to={`/podstawowe-zwroty/${createSlug(breed)}`}>
                <div className={styles.breedText}>
                  <p className={styles.text}>{breed}</p>
                </div>
              </Link>
            );
          })
          }
          {filteredBreeds.length <= 0 &&
          <>
            <p className={styles.breed}>Brak wyników</p>
            <p className={styles.breed}>Spróbuj ponownie później</p>
          </>
          }
        </section>
      </>
    );
  };
}

DogBreeds.propTypes = {
  breeds: PropTypes.array.isRequired,
};

export default DogBreeds;
