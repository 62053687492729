 import React from 'react';
import SEO from 'src/components/seo';
import cs from 'classnames';
import styles from 'src/pages/podstawowe-zwroty.module.scss';
import BigArticleTitle from 'src/components/shared/texts/BigArticleTitle/BigArticleTitle';
import Layout from 'src/Theme/layout';
import { graphql } from 'gatsby';
import DogBreeds from 'src/components/pages/dogBreeds/DogBreeds/DogBreeds';

const podstawoweZwroty = ({ data }) => {
const { allDatoCmsDogBreed: { nodes: breeds }} = data;
  return (
    <Layout>
      <SEO title="Rasy psów"/>
      <div className={cs(styles.dogsWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.title}>Podstawowe zwroty</BigArticleTitle>
        <DogBreeds breeds={breeds}/>
      </div>
    </Layout>
  );
};
export const query = graphql`
    {
        allDatoCmsDogBreed {
            nodes {
                breed
                id
            }
        }
    }
`;

export default podstawoweZwroty;



 // allDatoCmsPodcast {
 //     nodes {
 //         breeds {
 //             breed{
 //                 breed
 //             }
 //         }
 //     }
 // }
 // allDatoCmsArticle {
 //     nodes {
 //         breeds {
 //             breed{
 //                 breed
 //             }
 //         }
 //     }
 // }
